<template>
  <div class="flex flex-col items-center">
    <div class="max-w-5xl w-full">

      <h2 class="text-2xl font-medium p-4 border-b-2 border-red-600">
        Sales Incentive Plan Design
      </h2>

      <img
        src="../../assets/img/sales_people.jpg"
        alt="Sales Incentive Plan Design Banner"
        class="object-cover w-full lg:h-96 h-80 mt-4"
      >

      <div class="p-4">
        <p class="py-4">
          In the ever competitive environment, the push towards greater profits is rigorous.
          Paying right and rewarding results reinforces the motivation of your salesforce to
          stretch their limits and boost your company’s performance in the desired directions.
        </p>
        <p class="pb-4">
          How to determine the right target cash mix, upside, use of accelerators,
          decelerators, caps, avoid sales holdback and optimise cost and performance
          using well-thought out designs that can be understood? How to identify suitable
          performance measures and constraints in their use, funding of plans and
          linkage to incentives using formulas?
        </p>
        <p class="pb-4">
          MRC works together with HR and sales executives in designing sales
          incentive plan suitable for the organisation.
        </p>
      </div>
    </div>
  </div>
</template>

<style scoped>

</style>

<script>
export default {};
</script>
